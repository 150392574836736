import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '../src/sass/main.min.css';
import { HashRouter, Routes, Route } from "react-router-dom";
import { NavBar } from './components/resuable/nav';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <NavBar />
      <App />
    </HashRouter>
  </React.StrictMode>
);